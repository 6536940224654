<style scoped>
  .text-danger {
    color: red !important;
  }
</style>
<template>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-base">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="promotion.Code" disabled/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Name</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="promotion.Name"/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Description</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-textarea maxlength ="255" class="w-full" :label="charactersLeft" name="Description" v-model="promotion.Description" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Budget</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectedBudget"
                            :options="optionBudget"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="ID"
                            label="Code"
                            :disabled="false"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.Code }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Type</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.Type">
                        <label> Discount</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="2" v-model="promotion.Type">
                        <label> Free Item</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Base</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.Base" disabled>
                        <label> Quantity</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="2" v-model="promotion.Base" disabled>
                        <label> Value</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Unit</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.Unit">
                        <label> Line</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="2" v-model="promotion.Unit">
                        <label> Total</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Calculation</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="1" v-model="promotion.Calculation">
                        <label> Final</label>
                    </div>
                    <div class="vx-col sm:w-1/3">
                        <input type="radio" value="2" v-model="promotion.Calculation">
                        <label> Step Ladder</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Valid from</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="promotion.ValidFrom" placeholder="Select valid from"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Valid to</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="promotion.ValidTo" placeholder="Select valid to"></datepicker>
                </div>
            </div>
            <br>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-base">
        </div>
        <hr>
        <div class="vx-col w-full mb-base"><center><h3>Promotion Line</h3></center></div>
        <br>
        <div class="vx-col w-full mb-base">
            <div :key="index" v-for="(dt, index) in groupedPromotionLine">
                <PromotionLine :optionType="optionType" :line="dt" :promotionType="promotion.Type" v-on:deleteLine="deleteLine" v-on:browseItem="browseItem" v-on:freeItem="freeItem" v-on:setDefaultItemRequisitionType="setDefaultItemRequisitionType"></PromotionLine>
            </div>
            <div class="vx-col text-right">
                <vs-button size="small" class="mr-3 mb-2 vs-button-success" @click="addNewPromotionLine()">Add New Promotion Line</vs-button>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-6/12 w-full">
                    <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()">Submit</vs-button>
                    <!-- <vs-button v-if="update" color="warning" class="mr-3 mb-2" @click="ResetForm()">Reset</vs-button> -->
                    <vs-button v-if="update" color="danger" class="mr-3 mb-2" @click="CancelForm()">Cancel</vs-button>
                </div>
            </div>
        </div>

        <vs-popup :title="'Browse Item Available for line No : '+selectedLineNumber" :active.sync="optionItemAvailableShow">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                            <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <vs-dropdown-menu>                
                            <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                <span>{{item}}</span>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
                <div class="mx-auto"></div>
                <div class="vx-col sm:w-1/3 w-full">
                    <div class="flex flex-wrap items-center justify-between">
                        <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Type to search" />
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="optionItemAvailable" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th>Code</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>UOM</vs-th>
                            <vs-th>Action</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data">
                                <vs-td>{{dt.code}}</vs-td>
                                <vs-td style="align-item-center">{{dt.name}}</vs-td>                                
                                <vs-td>({{dt.uom}})</vs-td>
                                <vs-td style="align-item-center">
                                    <feather-icon title="Add" icon="PlusIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="addItemRequisition(selectedLineNumber,dt)"/>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                </div>
            </div>
        </vs-popup>

        <vs-popup fullscreen :title="'Free Item'" :active.sync="optionItemFreeItemShow">
            <div class="vx-row">
                <div class="vx-col sm:w-1/2 w-full">
                    <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/3 w-full">
                            <vs-dropdown vs-trigger-click class="cursor-pointer">
                                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                    <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>                
                                    <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                        <span>{{item}}</span>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                        <div class="mx-auto"></div>
                        <div class="vx-col sm:w-1/3 w-full">
                            <div class="flex flex-wrap items-center justify-between">
                                <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Type to search" />
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-table :data="optionItemAvailable" stripe style="width:100%">
                                <template slot="thead">
                                    <vs-th>Code</vs-th>
                                    <vs-th>Name</vs-th>
                                    <vs-th>UOM</vs-th>
                                    <vs-th>Action</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(dt, index) in data">
                                        <vs-td>{{dt.code}}</vs-td>
                                        <vs-td style="align-item-center">{{dt.name}}</vs-td>                                
                                        <vs-td>({{dt.uom}})</vs-td>
                                        <vs-td style="align-item-center">
                                            <feather-icon title="Add" icon="ChevronsRightIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="addFreeItem(selectedStep, dt)"/>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                            <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                        </div>
                    </div>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                    <div class="vx-col w-full mb-base">
                        <center>
                            <h3>Selected Free Item</h3>
                        </center>
                        <p>
                        Promotion Line No {{selectedLineNumber}}<br>
                        Step from {{selectedStep.From}} to {{selectedStep.To}}<br>
                        </p>
                        <p align="right">
                            <small>Choose any item you want to add as "Free Item" from the left panel</small>
                        </p>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-table :data="selectedStep.FreeItem" stripe style="width:100%">
                                <template slot="thead">
                                    <vs-th>Code</vs-th>
                                    <vs-th>Name</vs-th>
                                    <vs-th>UOM</vs-th>
                                    <vs-th>Qty</vs-th>
                                    <vs-th>Action</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(dt, index) in data">
                                        <vs-td>{{dt.ItemCode}}</vs-td>
                                        <vs-td style="align-item-center">{{dt.ItemName}}</vs-td>                                
                                        <vs-td>({{dt.ItemUnit}})</vs-td>
                                        <vs-td>
                                            <input v-model.number="dt.Qty" min=1 max=99 type="number">
                                        </vs-td>
                                        <vs-td style="align-item-center">
                                            <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteFreeItem(index)"/>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import flatPickr from 'vue-flatpickr-component';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/flatpickr.css';
import PromotionLine from "../component/promotion_line.vue";
// import moment from 'moment'

export default {
    props: ["territoryCode", "territoryId", "selectedPromotion", "optionTerritory"],
    components: {
        Datepicker,
        flatPickr,
        PromotionLine
    },
    mounted(){
        // console.log("userLogin : ", this.$userLogin);
        if(this.selectedPromotion && this.selectedPromotion.Code) {
            this.resetPromotion = Object.assign({}, this.selectedPromotion);
            this.promotion = this.selectedPromotion;
            
            this.promotion.ValidFrom = new Date(this.promotion.ValidFrom)
            this.promotion.ValidTo = new Date(this.promotion.ValidTo)

            this.update = true;
            this.selectedBudget = {
                ID: this.promotion.BudgetID,
                Code: this.promotion.Budget.Code,
            }

            this.groupByLineNumber()
        } else {
           this.setDefaultDate();
           this.resetPromotion = Object.assign({}, this.promotion);
        }

        this.getOptionBudget();
        this.setDefaultPromotionLineType();
    },
    watch: {
        promotionType: function() {
            this.setDefaultPromotionLineType()
        },
        search: function() {
            if(this.optionItemAvailableShow || this.optionItemFreeItemShow) {
                this.getOptionItemAvailable();
            }
        }
    },
    computed: {
        DueDate: {
            get() {
                var d = new Date()
                if(this.selectedPromotion && this.selectedPromotion.DueDate){
                    d = new Date(this.selectedPromotion.DueDate)
                    d.setHours(d.getHours() - 7)
                } else {
                    d = new Date(this.promotion.DueDate)
                }
                return d
            },
            set(val) {
                this.DueDatePost = val
                return val
            },
        },
        charactersLeft() {
            var char = this.promotion.Description.length,
                limit = 255;

            return "counter " + (limit - char);
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                if(this.optionItemAvailableShow || this.optionItemFreeItemShow) {
                    this.getOptionItemAvailable(page);
                }
                return page;
            }
        },
        promotionType() {
            return this.promotion.Type
        },
    },
    data: () => ({
        configFlatPickrDueDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y H:i',
            enableTime: true,
            minTime: "00:00",
            plugins: [new ConfirmDatePlugin()]
        },

        configFlatPickrDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y',
            enableTime: false,
        },

        hour: "",
        minute: "",

        update: false,

        selectedBudget:{},
        selectedLineNumber:1,
        selectedStep:[{}],

        resetPromotion: {},
        promotion: {
            Code: "",
            Name: "",
            Description: "",
            Type: 1,
            Base: 1,
            Unit: 1,
            Calculation: 1,
            ValidFrom: "",
            ValidTo: "",
            BudgetID: "",
        },
        groupedPromotionLine: [{
            Number: 1,
            Type: null,
            Calculation: null,
            ItemReq: null,
            Step: null,
            SelectedType: {}
        }],

        optionType: [
            {ID: 1,Name: "Percentage"},
            {ID: 2,Name: "Amount"},
            {ID: 3,Name: "Fix Amount"},
            {ID: 4,Name: "Fix Item"},
            {ID: 5,Name: "Lowest Price"},
            {ID: 6,Name: "SKU"},
            {ID: 7,Name: "Merchandise"},
            // {ID: 8,Name: "Percentage"},
        ],
        optionBudget: [{}],
        optionItemAvailable: [{}],
        optionItemAvailableShow: false,
        optionItemFreeItemShow: false,

        limits: [10],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        search: "",
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
    }),
    methods: {
        addItemRequisition(selectedLineNumber,item) {
            let this_vs = this.$vs
            this.groupedPromotionLine.filter(function(data){ 
                if(data.Number == selectedLineNumber) {
                    if(data.ItemReq == null) {
                        data.ItemReq = []
                    }
                    const checkDuplicate = data.ItemReq.some(element => {
                        if (element.ItemCode == item.code && element.ItemUnit == item.uom) {
                            return true;
                        }
                    });

                    if(checkDuplicate) {
                        this_vs.notify({
                            title: "Warning",
                            text: "Duplicate item detected ",
                            color: "warning",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check"
                        });
                    } else {
                        data.ItemReq.push({
                            Line: selectedLineNumber,
                            ItemCode: item.code,
                            ItemName: item.name,
                            ItemUnit: item.uom,
                            ItemUnitID: item.item_unit_id,
                            Qty: 0,
                            Mandatory: false,
                            Multiply: false
                        })
                        this_vs.notify({
                            title: "Added",
                            text: item.name + "("+item.uom+ ")",
                            color: "primary",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check"
                        });
                    }
                }
            });
        },
        addNewPromotionLine() {
            let groupedItemReq = this.groupedPromotionLine
            let lastLineNumber = groupedItemReq[groupedItemReq.length-1].Number

            this.groupedPromotionLine.push(
                {
                    Number: lastLineNumber+1,
                    Type: null,
                    Calculation: null,
                    ItemReq: null,
                    Step: null,
                    SelectedType: {}
                }
            )
        },
        addFreeItem(selectedStep, newItem) {
            if(selectedStep.FreeItem == null) {
                selectedStep.FreeItem = []
            }
            selectedStep.FreeItem.push({
                ItemUnitID: newItem.item_unit_id,
                ItemCode: newItem.code,
                ItemName: newItem.name,
                ItemUnit: newItem.uom,
                Qty: 1,
            })
        },
        deleteLine(line) {
            let itemReq = this.groupedPromotionLine
            var filtered = itemReq.filter(function(data){ 
                return data.Number != line.Number;
            });

            // adjusted line number
            for (var i=0; i < filtered.length; i++) {
                filtered[i].Number= i+1
            }
            this.groupedPromotionLine = filtered
        },
        deleteFreeItem(index) {
            this.selectedStep.FreeItem.splice(index, 1);
        },
        groupByLineNumber() {
            // Promotion Line Item Requisition
            const itemReq = this.promotion.ItemRequisition
            let groupedItemLine = []
            let lastLineNumber = 0
            let temp = []
            for (var i=0; i < itemReq.length; i++) {
                if(lastLineNumber != itemReq[i].Line) {
                    if(temp.length > 0) {
                        let tempLine = {
                            Number: lastLineNumber,
                            Type: temp[0].Type,
                            Multiply: temp[0].Multiply,
                            Calculation: temp[0].Calculation,
                            ItemReq: temp,
                            Step: [],
                            SelectedType: {}
                        }
                        groupedItemLine.push(tempLine)
                    }
                    temp = []
                    temp.push(itemReq[i])
                } else {
                    temp.push(itemReq[i])

                }
                lastLineNumber = itemReq[i].Line

                // handle last array member
                if(i+1 == itemReq.length) {
                    groupedItemLine.push({
                        Number: lastLineNumber,
                        Type: temp[0].Type,
                        Multiply: temp[0].Multiply,
                        Calculation: temp[0].Calculation,
                        ItemReq: temp,
                        Step: [],
                        SelectedType: temp[0].SelectedType
                    })
                }
            }

            const steps = this.promotion.Step
            for (var j=0; j < steps.length; j++) {
                groupedItemLine.filter(function(data){
                    if(steps[j].Line == data.Number) {
                        data.Step.push(steps[j]);
                    }
                });
            }

            this.groupedPromotionLine = groupedItemLine
        },
        setDefaultItemRequisitionType(line){
            if(line.Type == 0 || line.Type == null) {
                line.SelectedType = {
                    ID: this.optionType[0].ID,
                    Name: this.optionType[0].Name,
                };
            } else {
                for (let index = 0; index < this.optionType.length; index++) {
                    if(this.optionType[index].ID == line.Type) {
                        line.SelectedType = {
                            ID: this.optionType[index].ID,
                            Name: this.optionType[index].Name,
                        };
                        break;
                    }   
                }
            }
        },
        checkDate() {
            let validFrom = this.promotion.ValidFrom
            let validTo = this.promotion.ValidTo

            if(validFrom.getFullYear() > validTo.getFullYear()) {
                console.log(validFrom.getFullYear(), validTo.getFullYear())
                return false;
            }

            if(validFrom.getMonth() > validTo.getMonth()) {
                console.log(validFrom.getMonth(), validTo.getMonth())
                return false;
            }

            if(validFrom.getMonth() == validTo.getMonth() && validFrom.getDate() > validTo.getDate()) {
                console.log(validFrom.getDate(), validTo.getDate())
                return false;
            }

            return true
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        ResetForm() {
            this.promotion = Object.assign({}, this.resetPromotion);
        },
        CancelForm() {
            this.update = false;
            this.resetPromotion = {
                Code: "",
                Name: "",
                Description: "",
                Type: 1,
                Base: 1,
                Unit: 1,
                Calculation: 1,
                ValidFrom: "",
                ValidTo: "",
                BudgetID: 0,
            };
            this.groupedPromotionLine = [{
                Number: 1,
                Type: null,
                Multiply: null,
                Calculation: null,
                ItemReq: null,
                Step: null,
                SelectedType: {}
            }]

            this.promotion = Object.assign({}, this.resetPromotion);
            this.selectedBudget = {};
            
            this.$emit("selectPromotion", {});
        },
        browseItem(line) {
            this.search = "";
            this.optionItemAvailableShow = true;
            this.optionItemFreeItemShow = false;
            this.selectedLineNumber = line.Number;
            this.getOptionItemAvailable();
            this.scrollToTop();
        },
        freeItem(line, step) {
            this.search = "";
            this.optionItemAvailableShow = false;
            this.optionItemFreeItemShow = true;
            this.selectedLineNumber = line.Number;
            this.selectedStep = step ? step : {};
            this.getOptionItemAvailable();
        },
        getOptionItemAvailable(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/item-available", {
                params: {
                    // territory_code : this.selectTerritoryCode,
                    search: this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1)
                }
            }).then(resp => {
                // console.log(resp.data);
                if (resp.code == 200) {
                    this.optionItemAvailable = resp.data.ItemsAvailable;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Item Available",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionBudget() {
            this.$vs.loading();

            this.$http.get("/api/v1/promotion-budget", {
                params: {
                    order: 'code',
                    sort: 'asc',
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionBudget = resp.data.Budgets;
                    if(this.optionBudget.length <= 0) {
                        return true
                    }
                    
                    if(this.promotion.BudgetID == 0 || this.promotion.BudgetID == null) {
                        this.selectedBudget = {
                            ID: this.optionBudget[0].DefaultAttribute.ID,
                            Code: this.optionBudget[0].Code,
                        };
                        this.promotion.Base = this.optionBudget[0].Type
                    } else {
                        for (let index = 0; index < this.optionBudget.length; index++) {
                            if(this.optionBudget[index].code == this.promotion.BudgetID) {
                                this.selectedBudget = {
                                    ID: this.optionBudget[index].DefaultAttribute.ID,
                                    Code: this.optionBudget[index].Code,
                                };
                                break;
                            }   
                        }
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Budget option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },

        setParam() {
            console.log("this.groupedPromotionLine", this.groupedPromotionLine)
            let arrLine = [];
            for (let i = 0; i < this.groupedPromotionLine.length; i++) {
                let arrLineReq = [];
                let arrLineStep = [];

                // item requisition
                let itemReq = this.groupedPromotionLine[i].ItemReq
                for (let j = 0; j < itemReq.length; j++) {
                    let lineReq = {
                        item_unit_id: itemReq[j].ItemUnitID,
                        // item_id: itemReq[j].ItemID,
                        item_name: itemReq[j].ItemName,
                        item_code: itemReq[j].ItemCode,
                        item_unit: itemReq[j].ItemUnit,
                        qty: itemReq[j].Qty,
                        mandatory: itemReq[j].Mandatory == 'true' ? true : false,
                        type: this.groupedPromotionLine[i].SelectedType.ID,
                        calculation: parseInt(itemReq[j].Calculation),
                        multiply: itemReq[j].Multiply == 'true' ? true : false,
                    }
                    arrLineReq.push(lineReq)
                }

                // step
                let itemStep = this.groupedPromotionLine[i].Step
                for (let k = 0; k < itemStep.length; k++) {
                    let freeItem = itemStep[k].FreeItem
                    let arrFreeItem = [];
                    for (let l = 0; l < freeItem.length; l++) {
                        let lineStepFreeItem = {
                            item_unit_id: freeItem[l].ItemUnitID,
                            item_name: freeItem[l].ItemName,
                            item_code: freeItem[l].ItemCode,
                            item_unit: freeItem[l].ItemUnit,
                            qty: freeItem[l].Qty,
                        }
                        arrFreeItem.push(lineStepFreeItem)
                    }
                    
                    let lineStep = {
                        from               : itemStep[k].From,
                        to                 : itemStep[k].To,
                        discount           : itemStep[k].Discount,
                        additional_discount : itemStep[k].AdditionalDiscount,
                        discount_per : itemStep[k].DiscountPer,
                        cap                : itemStep[k].Cap,
                        free_item                : arrFreeItem,
                    }

                    arrLineStep.push(lineStep)
                }

                let line = {
                    requisition: arrLineReq,
                    step: arrLineStep
                }

                arrLine.push(line)
            }
            
            let param = {
                budget_id: this.selectedBudget.ID,
                code: this.promotion.Code,
                name: this.promotion.Name,
                description: this.promotion.Description,
                type: this.promotion.Type,
                base: this.promotion.Base,
                unit: this.promotion.Unit,
                calculation: this.promotion.Calculation,
                valid_from: this.promotion.ValidFrom,
                valid_to: this.promotion.ValidTo,
                line: arrLine
            }
            console.log("param : ", param)
            return param
        },
        
        SubmitForm() {
            if(this.promotion.ValidFrom == "" || this.promotion.ValidFrom == 0 || this.promotion.ValidFrom == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select valid from first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.promotion.ValidTo == "" || this.promotion.ValidTo == 0 || this.promotion.ValidTo == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select valid to first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(!this.checkDate()) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select valid to Date must be equal or after valid from",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.selectedBudget.Code == "" || this.selectedBudget.Code == 0 || this.selectedBudget.Code == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Currency",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            this.$vs.loading();
            this.$http
            .post("/api/v2/promotion/store", this.setParam())
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "Promotion has been saved",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.CancelForm();
                    this.setDefaultDate();
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        setDefaultDate() {
            var day = new Date();
            var nextDay = new Date(day);
            nextDay.setDate(day.getDate() + 1); 

            this.promotion.Date = day
            this.promotion.DueDate = nextDay
        },
        getTime(id) {
            this.$vs.loading();
            this.$http.get("/api/v1/master/customer/work-time/"+id).then(resp => {
                if (resp.code == 200) {
                   var day = new Date();
                    var nextDay = new Date(day);
                    nextDay.setDate(day.getDate() + 1);
                    nextDay.setHours(resp.data[0])
                    nextDay.setMinutes(resp.data[1])
                    this.promotion.DueDate = nextDay
                }
                this.$vs.loading.close();
            });
        },
        setDefaultPromotionLineType() {
            // all possibilities
            // this.optionType = [
            //     {ID: 1,Name: "Percentage"},
            //     {ID: 2,Name: "Amount"},
            //     {ID: 3,Name: "Fix Amount"},
            //     {ID: 4,Name: "Fix Item"},
            //     {ID: 5,Name: "Lowest Price"},
            //     {ID: 6,Name: "SKU"},
            //     {ID: 7,Name: "Merchandise"},
            //     // {ID: 8,Name: "Percentage"},
            // ],

            // Jika type = "discount"
            if(this.promotion.Type==1) {
                this.optionType = [
                    {ID: 1,Name: "Percentage"},
                    {ID: 2,Name: "Amount"},
                    {ID: 3,Name: "Fix Amount"},
                    {ID: 4,Name: "Fix Item"},
                ]
            } else if(this.promotion.Type==2) {
                this.optionType = [
                    {ID: 5,Name: "Lowest Price"},
                    {ID: 6,Name: "SKU"},
                    {ID: 7,Name: "Merchandise"},
                ]
            }
        }
    },
};
</script>
