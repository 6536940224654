<template>
    <div ref="form_matrix">
        <div class="vx-row">
            <div class="vx-col w-1/2">
                <div class="mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Matrix</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" v-model="promotionMatrix.Code" disabled />
                    </div>
                </div>
                <div class="mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Name</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" v-model="promotionMatrix.Name" />
                    </div>
                </div>
                <div class="mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <span>Description</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input class="w-full" v-model="promotionMatrix.Description" />
                    </div>
                </div>
                <div class="mb-6">
                    <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(8)">Promotion</vs-button></vs-divider>
                    <vs-table class="vx-col w-full" :data="promotionMatrix.MatrixJson" stripe border>
                        <template slot="thead">
                            <vs-th>Code</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>Description</vs-th>
                            <vs-th style="flex:right; width: 10px;">Action</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="idx" v-for="(dt, idx) in data">
                                <vs-td>{{dt.V2Promotion.Code}}</vs-td>
                                <vs-td>{{dt.V2Promotion.Name}}</vs-td>
                                <vs-td>{{dt.V2Promotion.Description}}</vs-td>
                                <vs-td>
                                    <feather-icon title="Detail" icon="SearchIcon" svgClasses="w-5 h-5 text-primary stroke-current" class="ml-2" @click="detailPromotion(idx)"/>&nbsp;
                                    <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deletePromotion(idx)"/>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
            <div class="vx-col w-1/2">
                <TableOptionParameter ref="table_option_parameter" v-on:addParameter="addParameter"></TableOptionParameter>
            </div>
        </div>
        <div v-if="this.selectedPromotion != null">
            <div class="vx-col w-full">
                <center>
                    <h3>Matrix for : ({{this.selectedPromotion.V2Promotion.Code}}) {{this.selectedPromotion.V2Promotion.Name}}</h3>
                </center>
            </div>
            <div class="vx-row">
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(1)">Sales Area</vs-button></vs-divider>
                <vs-table class="vx-col w-full" :data="selectedPromotion.SalesArea" stripe border>
                    <template slot="thead">
                        <vs-th>Code</vs-th>
                        <vs-th>Name</vs-th>
                        <vs-th>Address</vs-th>
                        <vs-th>Description</vs-th>
                        <vs-th style="flex:right; width: 10px;">Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td>{{dt.Code}}</vs-td>
                            <vs-td>{{dt.Name}}</vs-td>
                            <vs-td>{{dt.Address}}</vs-td>
                            <vs-td>{{dt.Description}}</vs-td>
                            <vs-td><feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteParameter(1,idx)"/></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
            <hr class="mb-base">
            <div class="vx-row">
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(2)">Territory</vs-button></vs-divider>
                <vs-table class="vx-col w-full" :data="selectedPromotion.Territory" stripe border>
                    <template slot="thead">
                        <vs-th>Code</vs-th>
                        <vs-th>Name</vs-th>
                        <vs-th>Description</vs-th>
                        <vs-th style="flex:right; width: 10px;">Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td>{{dt.code}}</vs-td>
                            <vs-td>{{dt.name}}</vs-td>
                            <vs-td>{{dt.description}}</vs-td>
                            <vs-td><feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteParameter(2,idx)"/></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
            <hr class="mb-base">
            <div class="vx-row">
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(3)">Distribution Channel</vs-button></vs-divider>
                <vs-table class="vx-col w-full" :data="selectedPromotion.CustomerCategory" stripe border>
                    <template slot="thead">
                        <vs-th>Name</vs-th>
                        <vs-th>Classification</vs-th>
                        <vs-th>Description</vs-th>
                        <vs-th style="flex:right; width: 10px;">Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td>{{dt.name}}</vs-td>
                            <vs-td>{{dt.classification}}</vs-td>
                            <vs-td>{{dt.description}}</vs-td>
                            <vs-td><feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteParameter(3,idx)"/></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
            <hr class="mb-base">
            <div class="vx-row">
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(4)">Customer Group</vs-button></vs-divider>
                <vs-table class="vx-col w-full" :data="selectedPromotion.CustomerGroup" stripe border>
                    <template slot="thead">
                        <vs-th>Code</vs-th>
                        <vs-th>Name</vs-th>
                        <vs-th>Description</vs-th>
                        <vs-th style="flex:right; width: 10px;">Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td>{{dt.code}}</vs-td>
                            <vs-td>{{dt.name}}</vs-td>
                            <vs-td>{{dt.description}}</vs-td>
                            <vs-td><feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteParameter(4,idx)"/></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
            <hr class="mb-base">
            <div class="vx-row">
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(5)">Pricing</vs-button></vs-divider>
                <vs-table class="vx-col w-full" :data="selectedPromotion.PricingGroup" stripe border>
                    <template slot="thead">
                        <vs-th>Code</vs-th>
                        <vs-th>Name</vs-th>
                        <vs-th style="flex:right; width: 10px;">Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td>{{dt.code}}</vs-td>
                            <vs-td>{{dt.name}}</vs-td>
                            <vs-td><feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteParameter(5,idx)"/></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
            <hr class="mb-base">
            <div class="vx-row">
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(6)">Payment Term</vs-button></vs-divider>
                <vs-table class="vx-col w-full" :data="selectedPromotion.PaymentTerm" stripe border>
                    <template slot="thead">
                        <vs-th>Name</vs-th>
                        <vs-th>Due (days)</vs-th>
                        <vs-th style="flex:right; width: 10px;">Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td>{{dt.name}}</vs-td>
                            <vs-td>{{dt.due_invoice_period}}</vs-td>
                            <vs-td><feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteParameter(6,idx)"/></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
            <hr class="mb-base">
            <div class="vx-row">
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseParameter(7)">Delivery Type</vs-button></vs-divider>
                <vs-table class="vx-col w-full" :data="selectedPromotion.DeliveryType" stripe border>
                    <template slot="thead">
                        <vs-th>Code</vs-th>
                        <vs-th>Name</vs-th>
                        <vs-th style="flex:right; width: 10px;">Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="idx" v-for="(dt, idx) in data">
                            <vs-td>{{dt.Code}}</vs-td>
                            <vs-td>{{dt.Name}}</vs-td>
                            <vs-td><feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteParameter(7,idx)"/></vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <br>
        <div class="vx-row">
            <div class="vx-col sm:w-6/12 w-full">
                <vs-button class="mr-3 mb-2" v-on:click="submitFormMatrix()">Submit</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import TableOptionParameter from "../component/table_option_parameter.vue";
export default {
    components: {
        TableOptionParameter
    },
    props: ["selectedMatrix"],
    created(){
        // console.log("created!")
    },
    mounted(){
        // console.log("mounted!")
    },
    watch: {
        selectedMatrix: function() {
            this.promotionMatrix = this.selectedMatrix
        },
    },
    computed: {
        // 
    },
    data: () => ({
        promotionMatrix: {},
        selectedPromotion: null,
    }),
    methods: {
        scrollToTop() {
            const el = this.$refs.form_matrix
            if(el) { // if statement here is important!
                el.scrollIntoView();
            }
        },

        detailPromotion(idx) {
            this.selectedPromotion = this.promotionMatrix.MatrixJson[idx]
        },

        browseParameter(type) {
            const el = this.$refs.table_option_parameter
            if(el) { // if statement here is important!
                el.getParameterMatrixOption(type);
            }
            this.scrollToTop()
        },

        deleteParameter(type, index) {
            console.log("deleteParameter : ", type, index)
            let arr = []
            let arrComplete = []
            switch(type) {
                case 1:
                    arr = this.selectedPromotion.SalesAreaID 
                    arrComplete = this.selectedPromotion.SalesArea
                    break;
                case 2:
                    arr = this.selectedPromotion.TerritoryID 
                    arrComplete = this.selectedPromotion.Territory
                    break;
                case 3:
                    arr = this.selectedPromotion.CustomerCategoryID 
                    arrComplete = this.selectedPromotion.CustomerCategory
                    break;
                case 4:
                    arr = this.selectedPromotion.CustomerGroupID 
                    arrComplete = this.selectedPromotion.CustomerGroup
                    break;
                case 5:
                    arr = this.selectedPromotion.PricingGroupID 
                    arrComplete = this.selectedPromotion.PricingGroup
                    break;
                case 6:
                    arr = this.selectedPromotion.PaymentTermID 
                    arrComplete = this.selectedPromotion.PaymentTerm
                    break;
                case 7:
                    arr = this.selectedPromotion.DeliveryTypeID 
                    arrComplete = this.selectedPromotion.DeliveryType
                    break;
                case 8:
                    arr = this.promotionMatrix.MatrixJson // v2_promotion_id
                    break;
                default:
                    // code block
            }

            arr.splice(index, 1);
            if(arrComplete.length > 0 ) {
                arrComplete.splice(index, 1);
            }
        },

        addParameter(option) {
            let type = option.type
            let data = option.data
            let arr = []
            let arrComplete = []
            let checkDuplicate = false
            switch(type) {
                case 1: // sales_area_id
                    arr = this.selectedPromotion.SalesAreaID 
                    arrComplete = this.selectedPromotion.SalesArea
                    checkDuplicate = arr.some(element => {
                        if (element == data.ID) {
                            return true;
                        }
                    });
                    break;
                case 2: // territory_id
                    arr = this.selectedPromotion.TerritoryID 
                    arrComplete = this.selectedPromotion.Territory
                    checkDuplicate = arr.some(element => {
                        if (element == data.ID) {
                            return true;
                        }
                    });
                    break;
                case 3: // customer_category_id
                    arr = this.selectedPromotion.CustomerCategoryID 
                    arrComplete = this.selectedPromotion.CustomerCategory
                    checkDuplicate = arr.some(element => {
                        if (element == data.ID) {
                            return true;
                        }
                    });
                    break;
                case 4: // customer_group_id
                    arr = this.selectedPromotion.CustomerGroupID 
                    arrComplete = this.selectedPromotion.CustomerGroup
                    checkDuplicate = arr.some(element => {
                        if (element == data.ID) {
                            return true;
                        }
                    });
                    break;
                case 5: // pricing_group_id
                    arr = this.selectedPromotion.PricingGroupID 
                    arrComplete = this.selectedPromotion.PricingGroup
                    checkDuplicate = arr.some(element => {
                        if (element == data.ID) {
                            return true;
                        }
                    });
                    break;
                case 6: // payment_term_id
                    arr = this.selectedPromotion.PaymentTermID 
                    arrComplete = this.selectedPromotion.PaymentTerm
                    checkDuplicate = arr.some(element => {
                        if (element == data.ID) {
                            return true;
                        }
                    });
                    break;
                case 7: // delivery_type_id
                    arr = this.selectedPromotion.DeliveryTypeID 
                    arrComplete = this.selectedPromotion.DeliveryType
                    checkDuplicate = arr.some(element => {
                        if (element == data.ID) {
                            return true;
                        }
                    });
                    break;
                case 8:
                    arr = this.promotionMatrix.MatrixJson // v2_promotion_id
                    checkDuplicate = arr.some(element => {
                        if (element.V2PromotionID == data.ID) {
                            return true;
                        }
                    });
                    break;
            }

            if(checkDuplicate) {
                this.$vs.notify({
                    title: "Warning : Can't add this item",
                    text: "Duplicate item detected ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            } else {
                let temp = {}
                switch (type) {
                    case 8:
                        temp = {
                            Customer: [],
                            CustomerID: [],
                            CustomerCategory: [],
                            CustomerCategoryID: [],
                            CustomerGroup: [],
                            CustomerGroupID: [],
                            DeliveryType: [],
                            DeliveryTypeID: [],
                            PaymentTerm: [],
                            PaymentTermID: [],
                            PricingGroup: [],
                            PricingGroupID: [],
                            SalesArea: [],
                            SalesAreaID: [],
                            Territory: [],
                            TerritoryID: [],
                            V2Promotion: data,
                            V2PromotionID: data.ID
                        }
                        arr.push(temp);
                        break;
                    default:
                        arr.push(data.ID);
                        arrComplete.push(data);
                        break;
                }
                
                this.$vs.notify({
                    title: "Added",
                    text: data.Name,
                    color: "primary",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },

        getParameterMatrix(arr_id, type) {
            var parameter = {
                ids: arr_id,
                type: type
            };

            this.$http.get("/api/v2/promotion-matrix/parameter", {
                params: parameter
            }).then(resp => {
                if (resp.code == 200) {
                    switch(type) {
                        case 1:
                            this.parameter1 = resp.data.PromotionMatrixParameters;
                            break;
                        case 2:
                            this.parameter2 = resp.data.PromotionMatrixParameters;
                            break;
                        case 3:
                            this.parameter3 = resp.data.PromotionMatrixParameters;
                            break;
                        case 4:
                            this.parameter4 = resp.data.PromotionMatrixParameters;
                            break;
                        case 5:
                            this.parameter5 = resp.data.PromotionMatrixParameters;
                            break;
                        case 6:
                            this.parameter6 = resp.data.PromotionMatrixParameters;
                            break;
                        case 7:
                            this.parameter7 = resp.data.PromotionMatrixParameters;
                            break;
                        case 8:
                            this.parameter8 = resp.data.PromotionMatrixParameters;
                            break;
                        default:
                            // code block
                    }
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },

        getAllParameterMatrixData() {
            let arr_id_1 = this.promotionMatrix.MatrixJson.sales_area_id
            this.getParameterMatrix(arr_id_1, 1)

            let arr_id_2 = this.promotionMatrix.MatrixJson.territory_id
            this.getParameterMatrix(arr_id_2, 2)

            let arr_id_3 = this.promotionMatrix.MatrixJson.customer_category_id
            this.getParameterMatrix(arr_id_3, 3)
            
            let arr_id_4 = this.promotionMatrix.MatrixJson.customer_group_id
            this.getParameterMatrix(arr_id_4, 4)
            
            let arr_id_5 = this.promotionMatrix.MatrixJson.pricing_group_id
            this.getParameterMatrix(arr_id_5, 5)
            
            let arr_id_6 = this.promotionMatrix.MatrixJson.payment_term_id
            this.getParameterMatrix(arr_id_6, 6)
            
            let arr_id_7 = this.promotionMatrix.MatrixJson.delivery_type_id
            this.getParameterMatrix(arr_id_7, 7)

            let arr_id_8 = this.promotionMatrix.MatrixJson.v2_promotion_id
            this.getParameterMatrix(arr_id_8, 8)
        },

        resetAllParameterMatrixData() {
            this.parameter1 = []
            this.parameter2 = []
            this.parameter3 = []
            this.parameter4 = []
            this.parameter5 = []
            this.parameter6 = []
            this.parameter7 = []
            this.parameter8 = []
        },

        setParam() {
            
            let matrix = []
            for (let index = 0; index < this.promotionMatrix.MatrixJson.length; index++) {
                const element = this.promotionMatrix.MatrixJson[index];
                let temp = {
                    sales_area_id: element.SalesAreaID,
                    territory_id: element.TerritoryID,
                    customer_category_id: element.CustomerCategoryID,
                    customer_group_id: element.CustomerGroupID,
                    pricing_group_id: element.PricingGroupID,
                    payment_term_id: element.PaymentTermID,
                    delivery_type_id: element.DeliveryTypeID,
                    v2_promotion_id: element.V2PromotionID,
                }
                matrix.push(temp)
            }

            let params = {
                code: this.promotionMatrix.Code,
                name: this.promotionMatrix.Name,
                description: this.promotionMatrix.Description,
                matrix_json : matrix,
            }
            console.log("params", params)
            return params
        },

        submitFormMatrix() {
            this.$vs.loading();
            this.$http
            .post("/api/v2/promotion-matrix/store", this.setParam())
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "Promotion Matrix has been saved",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.$emit("closeForm");
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },

        pluck(array, key) {
            let result = array.map(a => a[key]);
            return result
        }
    }
}

</script>