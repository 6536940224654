<template>
    <div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <div class="vx-row vx-col sm:w-1/2 mb-6">
                    <vs-button size="small" class="mr-3 mb-2">No. {{line.Number}}</vs-button>
                    <vs-button v-if="line.Number > 1" size="small" class="mr-3 mb-2 vs-button-danger" @click="deleteLine(line)">x</vs-button>
                </div>
                <div class="vx-row vx-col sm:w-1/2 mb-6">
                    <div class="vx-col sm:w-1/6 w-full">
                        <span>Type</span>
                    </div>
                    <div class="vx-col sm:w-5/6 w-full">
                        <multiselect
                                class="selectExample"
                                v-model="line.SelectedType"
                                :options="optionType"
                                :multiple="false"
                                :allow-empty="false"
                                :group-select="false"
                                :max-height="120"
                                :limit="3"
                                placeholder=" Type to search"
                                track-by="ID"
                                label="ID"
                                :disabled="false"
                            >
                            <template slot="singleLabel" slot-scope="dt">
                                <span class="option__desc">
                                    <span class="option__title">{{ dt.option.Name }}</span>
                                </span>
                            </template>

                            <template slot="option" slot-scope="dt">
                                <div class="option__desc">
                                    <span class="option__title">{{ dt.option.Name }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <div class="vx-row vx-col sm:w-1/2 mb-6"  v-if="inputMultiply">
                    <div class="vx-col sm:w-1/6 w-full">
                        <span>Multiply</span>
                    </div>
                    <div class="vx-col vx-row sm:w-5/6 w-full">
                        <div class="vx-col sm:w-1/3">
                            <input type="radio" :name="`radio_multiply_line_${line.Number}`" value=true v-model="line.Multiply">
                            <label> Yes</label>
                        </div>
                        <div class="vx-col sm:w-1/3">
                            <input type="radio" :name="`radio_multiply_line_${line.Number}`" value=false v-model="line.Multiply">
                            <label> No</label>
                        </div>
                    </div>
                </div>
                <div class="vx-row vx-col sm:w-1/2 mb-6">
                    <div class="vx-col sm:w-1/6 w-full">
                        <span>Discounted</span>
                    </div>
                    <div class="vx-col vx-row sm:w-5/6 w-full">
                        <div class="vx-col sm:w-1/3">
                            <input type="radio" value="1" v-model="line.Calculation">
                            <label> Yes</label>
                        </div>
                        <div class="vx-col sm:w-1/3">
                            <input type="radio" value="2" v-model="line.Calculation">
                            <label> No</label>
                        </div>
                    </div>
                </div>
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="browseItem(line)">Add Item Requisition</vs-button></vs-divider>
                <vs-table :data="line.ItemReq" stripe style="width:100%">
                    <template slot="thead">
                        <vs-th>No</vs-th>
                        <vs-th>Code</vs-th>
                        <vs-th>Name</vs-th>
                        <vs-th>UoM</vs-th>
                        <vs-th v-if="inputQty">Quantity</vs-th>
                        <vs-th v-if="inputMandatory">Mandatory</vs-th>
                        <vs-th>Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="index" v-for="(dt, index) in data">
                            <vs-td style="align-item-right">{{index+1}}</vs-td>
                            <vs-td style="align-item-left">{{ dt.ItemCode }}</vs-td>
                            <vs-td style="align-item-left">{{ dt.ItemName }}</vs-td>
                            <vs-td style="align-item-center">{{ dt.ItemUnit }}</vs-td>
                            <vs-td v-if="inputQty">
                                <vue-number-input
                                    inline
                                    controls
                                    :min="0"
                                    :step="1"
                                    size="small"
                                    v-model="dt.Qty"
                                    :value="0"
                                    @keypress="isNumber($event)"
                                />
                            </vs-td>
                            <vs-td v-if="inputMandatory">
                                <input
                                type="checkbox"
                                v-model="dt.Mandatory"
                                true-value=true
                                false-value=false
                                >
                            </vs-td>
                            <vs-td>
                                <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="deleteItemRequisition(index)"/>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <vs-divider style="width: 100%;"><vs-button size="small" class="mr-3 mb-2 vs-button-dark" @click="addStep(line)">Add Step</vs-button></vs-divider>
                <vs-table :data="line.Step" stripe style="width:100%">
                    <template slot="thead">
                        <vs-th>No</vs-th>
                        <vs-th>From</vs-th>
                        <vs-th>To</vs-th>
                        <vs-th>Main</vs-th>
                        <vs-th v-if="inputAdditionalDiscount">Additional</vs-th>
                        <vs-th v-if="inputDiscountPer">Unit</vs-th>
                        <vs-th v-if="inputCap">Cap</vs-th>
                        <vs-th v-if="inputFreeItem">SKU</vs-th>
                        <vs-th>Action</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="index" v-for="(dt, index) in data">
                            <vs-td style="align-item-right">{{index+1}}</vs-td>
                            <vs-td>
                                <vue-number-input
                                    inline
                                    controls
                                    :min="0"
                                    :step="1"
                                    size="small"
                                    v-model="dt.From"
                                    :value="0"
                                    @keypress="isNumber($event)"
                                />
                            </vs-td>
                            <vs-td>
                                <vue-number-input
                                    inline
                                    controls
                                    :min="0"
                                    :step="1"
                                    size="small"
                                    v-model="dt.To"
                                    :value="0"
                                    @keypress="isNumber($event)"
                                />
                            </vs-td>
                            <vs-td>
                                <vue-number-input
                                    inline
                                    controls
                                    :min="0"
                                    :step="1"
                                    size="small"
                                    v-model="dt.Discount"
                                    :value="0"
                                    @keypress="isNumber($event)"
                                />
                            </vs-td>
                            <vs-td v-if="inputAdditionalDiscount">
                                <vue-number-input
                                    inline
                                    controls
                                    :min="0"
                                    :step="1"
                                    size="small"
                                    v-model="dt.AdditionalDiscount"
                                    :value="0"
                                    @keypress="isNumber($event)"
                                />
                            </vs-td>
                            <vs-td v-if="inputDiscountPer">
                                <vue-number-input
                                    inline
                                    controls
                                    :min="0"
                                    :step="1"
                                    size="small"
                                    v-model="dt.DiscountPer"
                                    :value="0"
                                    @keypress="isNumber($event)"
                                />
                            </vs-td>
                            <vs-td v-if="inputCap">
                                <vue-number-input
                                    inline
                                    controls
                                    :min="0"
                                    :step="1"
                                    size="small"
                                    v-model="dt.Cap"
                                    :value="0"
                                    @keypress="isNumber($event)"
                                />
                            </vs-td>
                            <vs-td v-if="inputFreeItem">
                                <feather-icon title="Free Item" icon="BoxIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="freeItem(line, dt)"/>
                                &nbsp;{{dt.FreeItem.length}} items
                            </vs-td>
                            <vs-td>
                                <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="deleteStep(index)"/>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <hr>
        <br>
    </div>
</template>

<script>
    import VueNumberInput from "@chenfengyuan/vue-number-input";
    export default {
        props: ["optionType","line", "promotionType"],
        components: {
            VueNumberInput
        },
        mounted(){
            this.showOrHide();
        },
        computed: {
            watchedProperties() {
                return `${this.line.SelectedType.ID}||${this.line.Multiply}||${this.line.Calculation}`;
            },
        },
        watch: {
            promotionType: function() {
                this.line.SelectedType = {}
                this.$emit("setDefaultItemRequisitionType", this.line);
                this.showOrHide()
            },
            // promotionType: { //<-- Edited
            //     handler: function(newVal, oldVal) {
            //         console.log("promotionType changed", newVal, oldVal)
            //         if(oldVal!=0||oldVal!=null||oldVal!=undefined) {
            //             this.line.SelectedType = {}
            //         }
            //         this.showOrHide()
            //     },
            // },
            watchedProperties: function() {
                this.updateChild();
                this.showOrHide();
            },
        },
        data: () => ({
            inputMultiply: true,
            inputQty : true,
            inputMandatory : false, // sementara false dulu, nanti diubah jadi true biar seragam, karena poromotion type fokus ngerjain dicount dan free item dulu
            inputAdditionalDiscount: true,
            inputCap: true,
            inputDiscountPer: true,
            inputFreeItem: true,
        }),
        methods: {
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            updateChild() {
                if(this.line.ItemReq) {
                    for (let i = 0; i < this.line.ItemReq.length; i++) {
                        this.line.ItemReq[i].Type = this.line.SelectedType.ID
                        this.line.ItemReq[i].Calculation = this.line.Calculation
                        this.line.ItemReq[i].Multiply = this.line.Multiply
                    }
                }
            },
            deleteLine(line) {
                this.$emit("deleteLine", line);
                this.$vs.notify({
                    title: "Promotion Line Deleted",
                    text: "Line number adjusted accordingly",
                    color: "primary",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            },
            browseItem(line){
                this.$emit("browseItem", line);
            },
            freeItem(line, step){
                this.$emit("freeItem", line, step);
            },
            addStep(line){
                if(this.line.Step == null) {
                    this.line.Step = []
                }
                this.line.Step.push({
                    Line: line.Number,
                    From: null,
                    To: null,
                    Discount: null,
                    AdditionalDiscount: null,
                    DiscountPer: null,
                    Cap: null,
                    FreeItem: [],
                })
            },
            deleteItemRequisition(index) {
                this.line.ItemReq.splice(index, 1);
            },
            deleteStep(index) {
                this.line.Step.splice(index, 1);
            },
            promotionDiscountLogic(){
                this.inputMultiply = false
                this.line.Multiply = false;
                this.inputFreeItem = false
                this.emptyFreeItem()

                // visibility for column Additional Discount & Cap
                if(this.line.SelectedType.ID == 1) {
                    this.inputQty = false
                    this.allQty(0)

                    this.inputAdditionalDiscount = true
                    this.inputCap = true
                } else {
                    this.inputAdditionalDiscount = false
                    this.inputCap = false
                    this.allAdditionalDiscount(0)
                    this.allCap(0)
                }

                // visibility for column Unit
                let arr_unit = [1,3,4]
                if(arr_unit.includes(this.line.SelectedType.ID)) {
                    this.inputDiscountPer = false
                    this.allDiscountPer(0)
                } else {
                    this.inputDiscountPer = true
                }
            },
            promotionFreeItemLogic() {
                this.inputMultiply = true
                this.inputFreeItem = true
                
                // visibility for column SKU
                if(this.line.SelectedType.ID == 5) {
                    this.inputFreeItem = false
                    this.emptyFreeItem()
                }
            },
            showOrHide() {
                // optionType: [
                // {ID: 1,Name: "Percentage"},
                // {ID: 2,Name: "Amount"},
                // {ID: 3,Name: "Fix Amount"},
                // {ID: 4,Name: "Fix Item"},
                // {ID: 5,Name: "Lowest Price"},
                // {ID: 6,Name: "SKU"},
                // {ID: 7,Name: "Merchandise"},
                // ],
                
                // 1: discount; 2: free item;
                if(this.promotionType==1) {
                    this.promotionDiscountLogic()
                } else {
                    this.promotionFreeItemLogic()
                }
            },
            allQty(newValue) {
                if(this.line.ItemReq) {
                    for (let i = 0; i < this.line.ItemReq.length; i++) {
                        this.line.ItemReq[i].Qty = newValue
                    }
                }
            },
            allAdditionalDiscount(newValue) {
                if(this.line.Step) {
                    for (let i = 0; i < this.line.Step.length; i++) {
                        this.line.Step[i].AdditionalDiscount = newValue
                    }
                }
            },
            allCap(newValue) {
                if(this.line.Step) {
                    for (let i = 0; i < this.line.Step.length; i++) {
                        this.line.Step[i].Cap = newValue
                    }
                }
            },
            allDiscountPer(newValue) {
                if(this.line.Step) {
                    for (let i = 0; i < this.line.Step.length; i++) {
                        this.line.Step[i].DiscountPer = newValue
                    }
                }
            },
            emptyFreeItem() {
                if(this.line.Step) {
                    for (let i = 0; i < this.line.Step.length; i++) {
                        this.line.Step[i].FreeItem = [{}]
                    }
                }
            }
        },
    };
</script>