<template>
    <div v-if="parameterType > 0">
        <center><h3>Option for parameter : {{ parameterType ? parameterTypeStrings[parameterType-1] : '' }}</h3></center>
        <br>
        <vs-table class="vx-col w-full" :data="optionParameter" stripe border>
            <template slot="thead">
                <vs-th>Code/Name</vs-th>
                <vs-th>Description</vs-th>
                <vs-th style="flex:right; width: 10px;">Action</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="idx" v-for="(dt, idx) in data">
                    <vs-td v-if="parameterType!=8">
                        {{ (dt.code) ? '('+dt.code+') ' : '' }} {{dt.name}}
                    </vs-td>
                    <vs-td v-if="parameterType==8">
                        {{ (dt.Code) ? '('+dt.Code+') ' : '' }} {{dt.Name}}
                    </vs-td>
                    <vs-td v-if="parameterType!=8">{{ (dt.description) ? dt.description : '-' }}</vs-td>
                    <vs-td v-if="parameterType==8">{{ (dt.Description) ? dt.Description : '-' }}</vs-td>
                    <vs-td><feather-icon title="Delete" icon="PlusIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="addParameter(parameterType, dt)"/></vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
    </div>
</template>

<script>
    export default {
        props: [],
        components: {
            // 
        },
        mounted(){
            // 
        },
        computed: {
            // 
        },
        watch: {
            // 
        },
        data: () => ({
            parameterType: null,
            parameterTypeStrings: ["Sales Area", "Territory", "Distribution Channel", "Customer Group", "Pricing", "Payment Term", "Delivery Type", "Promotion"],
            optionParameter: [],
        }),
        methods: {
            addParameter(type, dataParameter) {
                // untuk handle perbedaan format penulisan response
                // ada yang camel case, dan ada yang snake
                let data = {}
                switch(type) {
                    case 1:
                        data.ID = dataParameter.id
                        data.Code = dataParameter.code
                        data.Name = dataParameter.name
                        data.Address = dataParameter.address
                        data.Description = dataParameter.description
                        break;
                    case 7:
                        data.ID = dataParameter.id
                        data.Code = dataParameter.code
                        data.Name = dataParameter.name
                        break;
                    case 8:
                        data = dataParameter
                        data.ID = dataParameter.DefaultAttribute.ID
                        break;
                    default:
                        data = dataParameter
                        data.ID = (dataParameter.ID) ? dataParameter.ID : dataParameter.id
                }
                this.$emit("addParameter", {
                    type: type,
                    data: data
                });
            },
            getParameterMatrixOption(type) {
                this.parameterType = type
                let url = ""
                switch(type) {
                    case 1:
                        url = "/api/v1/master/territory-area"
                        break;
                    case 2:
                        url = "/api/v1/master/territory"
                        break;
                    case 3:
                        url = "/api/v1/master/customer-category"
                        break;
                    case 4:
                        url = "/api/v1/master/customer-group"
                        break;
                    case 5:
                        url = "/api/v1/master/pricing-group"
                        break;
                    case 6:
                        url = "/api/v1/master/payment-term"
                        break;
                    case 7:
                        url = "/api/v1/master/delivery-type"
                        break;
                    case 8:
                        url = "/api/v2/promotion"
                        break;
                }

                var parameter = {
                    length: 5,
                    // search: null
                };

                this.$http.get(url, {
                    params: parameter
                }).then(resp => {
                    if (resp.code == 200) {
                        // handle beda format response
                        if(type==8) {
                            this.optionParameter = resp.data.Promotions;
                        } else {
                            this.optionParameter = resp.data.records;
                        }
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                });
            },
        },
    };
</script>